import http from '@/utils/http'

const member = {
  list(data) {
    return http.post('/api/member/list', data)
  },
  updateBlock(data) {
    return http.post('/api/member/updateblock', data)
  },
}

export default member;