/**Created by liaoyingchao on 11/24/22.*/

<template>
  <div class="member page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="100px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="会员名称">
            <el-input v-model="form.memberName" clearable placeholder="请输入会员名称"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="会员手机号">
            <el-input v-model="form.memberPhone" clearable placeholder="请输入会员手机号"></el-input>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="memberName"
                    label="会员名称"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="memberHead"
                    label="会员头像"
                    width="100">
              <template #default="scope">
                <div v-if="scope.row.memberHead">
                  <img style="display: block;width: 50px;height: 50px;" :src="scope.row.memberHead">
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    prop="memberPhone"
                    label="会员手机号"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="加入时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="会员状态"
                    min-width="100">
              <template #default="scope">
                {{ scope.row.memberBlock == 1 ? '已禁用' : '正常' }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
              <template #default="scope">
                <el-button @click="enableEvent(scope.row)" type="success" text v-if="scope.row.memberBlock == 1">启用</el-button>
                <el-button @click="disableEvent(scope.row)" type="warning" text v-else>禁用</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
  </div>
</template>

<script setup>
  import {ref} from 'vue'
  import {
    Search,
  } from '@element-plus/icons-vue'
  import TableContainer from '@/components/table-container/index'
  import memberApi from '@/api/member'
  import { timeYYYY_MM_DD_HH_mm_ss } from '@/utils/time-format.js'
  import {ElMessage} from 'element-plus';

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = ref({
    memberName: '',
  })

  function getList(data) {
    return memberApi.list(data)
  }

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }
  
  function disableEvent(item) {
    pageLoading.value = true
    memberApi.updateBlock({
      memberBlock: 1,
      memberId: item.memberId
    }).then(res => {
      if (res.code == 0) {
        ElMessage.success('禁用成功！');
        // filterEvent();
        TabelContainer.value.loadData()
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function enableEvent(item) {
    pageLoading.value = true
    memberApi.updateBlock({
      memberBlock: 0,
      memberId: item.memberId
    }).then(res => {
      if (res.code == 0) {
        ElMessage.success('启用成功！');
        // filterEvent();
        TabelContainer.value.loadData()
      } else {
        ElMessage.error(res.msg);
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }
</script>

<style lang="stylus" scoped type="text/stylus">
  .member {

  }
</style>